$('.translate').click(function () {
    $('.translate').removeClass('active');
    $(this).addClass('active');
});


$(document).ready(function () {
    var fullpage_api = new fullpage('#fullPage', {
        autoScrolling: true
    });

    $(document).on('click', '.jsAnchorLink', function(e) {        
        e.preventDefault();    
        var slideId = parseInt($(this).attr('data-anchor'));        
        fullpage_api.moveTo(slideId);
    })
});


